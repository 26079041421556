/**
 * ファイルに関するユーティリティー関数群
 * @module idis/util/FilesUtils
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/window',
    'dojo/Deferred',
    'dojo/dom-construct',
    'dojo/dom-style',
    'idis/model/UserInfo',
    'idis/view/dialog/InfoDialog',
    'exif-js/exif'
], function(module, array, win, Deferred, domConstruct, domStyle, UserInfo, InfoDialog, exif) {
    /**
     * JPGとみなす拡張子一覧
     * @type {string[]}
     * @private
     */
    var _JPG_EXT_LIST = ['jpg', 'jpeg'];

    /**
     * 画像とみなす拡張子一覧
     * @type {string[]}
     * @private
     */
    var _IMAGE_EXT_LIST = _JPG_EXT_LIST.concat(['gif', 'png']);

    /**
     * 添付ファイルとしてアップロード可能な拡張子一覧
     * @type {string[]}
     * @private
     */
    var _ATTACH_EXT_LIST = _IMAGE_EXT_LIST.concat(['csv', 'txt']);

    return {
        /**
         * 指定されたファイル名が画像かどうかを判定する。
         * @param {string} fileName ファイル名
         * @returns {boolean} 指定されたファイル名が画像かどうか
         */
        isImageFile: function(fileName) {
            var ext = fileName.split('.').pop().toLowerCase();
            return array.indexOf(_IMAGE_EXT_LIST, ext) !== -1;
        },

        /**
         * 指定されたファイル名が画像かどうかを判定する。
         * @param {string} fileName ファイル名
         * @returns {boolean} 指定されたファイル名が画像かどうか
         */
        isJpgFile: function(fileName) {
            var ext = fileName.split('.').pop().toLowerCase();
            return array.indexOf(_JPG_EXT_LIST, ext) !== -1;
        },

        /**
         * 指定されたファイル名が添付ファイルとしてアップロード可能かどうかを判定し、
         * アップロード不可の場合、エラーメッセージを表示する。
         * @param {dojox/form/Uploader} attachFile アップローダー
         * @returns {boolean} アップロード可能な添付ファイルかどうか
         */
        isAttachFile: function(attachFile) {
            // LGWANの場合、拡張子制限なし
            if (UserInfo.getRunningMode()==='LGWAN') {
                return true;
            }

            var isAttachFile = array.some(attachFile._files, function(file){
                    var ext = file.name.split('.').pop().toLowerCase();
                    return array.indexOf(_ATTACH_EXT_LIST, ext) !== -1;
                });
            if(!isAttachFile){
                InfoDialog.show('入力チェック', '添付ファイルは画像ファイルかテキストファイルを選択してください');
                // uploaderをリセット
                attachFile.reset();
            }
            return isAttachFile;
        },

        /**
         * 指定されたFileオブジェクトからEXIF情報を読み取って返す。
         * @param {File} file Fileオブジェクト
         * @returns {Object} EXIF情報の辞書データ
         */
        readExif: function(file) {
            var dfd = new Deferred();
            var fileReader = new FileReader();
            fileReader.onload = function(evt) {
                dfd.resolve(exif.readFromBinaryFile(evt.target.result));
            };
            fileReader.onerror = dfd.reject;
            fileReader.readAsArrayBuffer(file);
            return dfd.promise;
        },

        /**
         * ファイルをダウンロードするメソッド。
         *
         * IE10+でファイル名が指定されている場合：
         *     xhrでダウンロードしmsSaveOrOpenBlobで保存する。
         * IE10+でファル名が指定されていない場合：
         *     レスポンスヘッダのContent-Dispositionからファイル名を取得できないため、window.openする。
         * IE9：
         *     window.openする。
         * IE8以下：
         *     対応しない。
         * それ以外：
         *     aタグを動的に生成し、クリックする。
         *     aタグにdownload属性を指定することで、ページ遷移ではなくダウンロードさせる。
         *     Safariは10.1未満はdownload属性に対応していないため動作しないため動作しない。
         *     aタグでのダウンロードの場合、ファイル名を指定しなくてもレスポンスヘッダの
         *     Content-Dispositionからファイル名を取得できることをChrome, Firefox, Safariで確認済み。
         * @param {string} url ダウンロード対象のURL
         * @param {string} filename 保存時のファイル名
         */
        downloadGet: function(url, filename) {
            // ブラウザの判定に使用する
            var ua = window.navigator.userAgent.toLowerCase();
            var ver = window.navigator.appVersion.toLowerCase();
            // IE10+
            if (window.navigator.msSaveOrOpenBlob) {
                // ファイル名が指定されている場合
                if(filename) {
                    // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
                    // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用
                    var xhr = new XMLHttpRequest();
                    xhr.open('GET', url, true);
                    xhr.responseType = 'blob';
                    xhr.onload = function() {
                        // バイナリのレスポンスをblobに入れる
                        var blob = new Blob([xhr.response], {
                            'type': 'application/octet-stream'
                        });
                        // レスポンスをファイルとして保存する
                        window.navigator.msSaveOrOpenBlob(blob, filename);
                    };
                    xhr.send();
                // ファイル名が指定されていない場合
                } else {
                    window.open(url);
                }
            // IE9
            } else if (ua.indexOf('msie') !== -1 && ver.indexOf('msie 9.') !== -1) {
                window.open(url);
            // その他のブラウザ
            } else {
                // aタグを生成
                // 生成するだけで配置しない
                console.log('else');
                var node = domConstruct.create('a', {
                    href: url,
                    download: filename
                });
                // aタグを非表示にする
                domStyle.set(node, 'display', 'none');
                // aタグを配置
                // chromeでは配置しなくてもクリックできたが、firefoxでは配置する必要があった
                domConstruct.place(node,win.body());
                // aタグをクリックする
                node.click();
                // aタグを破棄
                domConstruct.destroy('node');
            }
        }
    };
});
